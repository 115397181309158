* {
  font-family: 'gothambook';
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  overflow-y: hidden;
}

body ul {
  list-style-type: disc;
  list-style-position: inside;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .medium {
    font-family: 'gothammedium';
  }
  .bold {
    font-family: 'gothambold';
  }
  .gothamBlack {
    font-family: 'gothamblack';
  }
  .anitmate-numbers {
    font-variant: tabular-nums;
  }
}
