@font-face {
    font-family: 'gothamthin';
    src: url('https://jdsports-client-resources.s3.amazonaws.com/fonts/monotype-fonts/jd-sports/Gotham-Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'gothamthinitalic';
    src: url('https://jdsports-client-resources.s3.amazonaws.com/fonts/monotype-fonts/jd-sports/Gotham-ThinItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'gothamxlight';
    src: url('https://jdsports-client-resources.s3.amazonaws.com/fonts/monotype-fonts/jd-sports/Gotham-XLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'gothamxlightitalic';
    src: url('https://jdsports-client-resources.s3.amazonaws.com/fonts/monotype-fonts/jd-sports/Gotham-XLightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'gothamlight';
    src: url('https://jdsports-client-resources.s3.amazonaws.com/fonts/monotype-fonts/jd-sports/Gotham-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'gothamlightitalic';
    src: url('https://jdsports-client-resources.s3.amazonaws.com/fonts/monotype-fonts/jd-sports/Gotham-LightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'gothambook';
    src: url('https://jdsports-client-resources.s3.amazonaws.com/fonts/monotype-fonts/jd-sports/Gotham-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'gothambookitalic';
    src: url('https://jdsports-client-resources.s3.amazonaws.com/fonts/monotype-fonts/jd-sports/Gotham-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'gothammedium';
    src: url('https://jdsports-client-resources.s3.amazonaws.com/fonts/monotype-fonts/jd-sports/Gotham-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'gothammediumitalic';
    src: url('https://jdsports-client-resources.s3.amazonaws.com/fonts/monotype-fonts/jd-sports/Gotham-MediumItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'gothambold';
    src: url('https://jdsports-client-resources.s3.amazonaws.com/fonts/monotype-fonts/jd-sports/Gotham-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'gothambolditalic';
    src: url('https://jdsports-client-resources.s3.amazonaws.com/fonts/monotype-fonts/jd-sports/Gotham-BoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'gothamblack';
    src: url('https://jdsports-client-resources.s3.amazonaws.com/fonts/monotype-fonts/jd-sports/Gotham-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'gothamblackitalic';
    src: url('https://jdsports-client-resources.s3.amazonaws.com/fonts/monotype-fonts/jd-sports/Gotham-BlackItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'gothamultra';
    src: url('https://jdsports-client-resources.s3.amazonaws.com/fonts/monotype-fonts/jd-sports/Gotham-Ultra.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'gothamultraitalic';
    src: url('https://jdsports-client-resources.s3.amazonaws.com/fonts/monotype-fonts/jd-sports/Gotham-UltraItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}