.showScrollbars::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

.showScrollbars::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(111, 111, 111, .4);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}